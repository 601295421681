import React, { Component } from "react";
import { connect } from "react-redux";
import { configureHeader } from "@actions/ui";
import { loadCurators } from "@actions";
import Loading from "@components/global/loading-with-container";
import Footer from "@components/global/footer/footer";
import UserTile from "@components/global/tiles/user-tile";
import { CURATOR_TILE } from "@constants/content-tile-types";
import { getActiveLocations, currentLocation } from "@selectors/filters";
import { GA } from "@constants/config";
import { META } from "../constants/config";

class Curators extends Component {
  componentDidMount = () => {
    const { loadCurators } = this.props;
    window?.ga?.("send", "pageview", GA.PAGES.curators);
    window?.scrollTo?.(0, 0);
    this.props.configureHeader({
      headerVisibilityLocked: true,
    });

    loadCurators();
  };

  getCurrentRegion = () => {
    const { currentRegion } = this.props;
    if (currentRegion) return currentRegion.value;
    return "los_angeles";
  };

  renderCurators = () => {
    let {
      regions,
      curators: { data: curators },
    } = this.props;
    const currentRegion = this.getCurrentRegion();
    regions = regions.sort((region) => region.value !== currentRegion);
    return regions.map((region) => {
      let locationBasedCurators = curators
        .filter((curator) => curator.region === region.value)
        .map((curator) => {
          return (
            <UserTile
              key={String(curator.id)}
              type={CURATOR_TILE}
              column="col s12 m4 l3"
              user={curator}
              showEditButton={false}
            />
          );
        });

      return (
        <div
          className="row row--location"
          key={String("curator-section-" + region.value)}
        >
          <div className="col s12 heading heading--secondary">
            <h2 className="heading__headline heading__headline--grey-bg">
              <span>{region.label}</span>
            </h2>
          </div>
          {locationBasedCurators}
        </div>
      );
    });
  };

  render() {
    const {
      curators: { loading, exist },
    } = this.props;
    return (
      <div className="curators-container">
        <div className="container container__content">
          <div className="row">
            <div className="col s12">
              <div className="heading heading--primary">
                <h1 className="heading__headline">Curators</h1>
                <span className="heading__divider" />
                <p className="heading__copy">
                  Your friends on the frontlines of nightlife.
                </p>
              </div>
            </div>
          </div>
          {loading && <Loading size={"100%"} />}
          {exist && this.renderCurators()}
        </div>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    users: state.eventUsers.data,
    curators: state.eventUsers.curators,
    score: state.eventUsers.score,
    regions: getActiveLocations(state),
    currentRegion: currentLocation(state),
    browser: state.browser,
    platform: state.platform,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    configureHeader: (val) => {
      dispatch(configureHeader(val));
    },
    loadCurators: () => {
      dispatch(loadCurators()).catch((err) => err);
    },
  };
};

const CuratorsRedux = connect(mapStateToProps, mapDispatchToProps)(Curators);

export default CuratorsRedux;

export const Head = () => (
  <>
    <title>{`${META.title} | Curators`}</title>
    <link
      rel="canonical"
      href={`${process.env.GATSBY_TTF_SITE_URL}/curators`}
    />
    <meta property="og:title" content={`${META.title} | Curators`} />
  </>
);
